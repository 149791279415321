import React, { useEffect, useState, useRef, useMemo } from "react";
import { Link } from "react-router-dom";
import withRouter from "components/Common/withRouter";
import TableContainer from "../../../components/Common/TableContainer";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  FormFeedback,
  UncontrolledTooltip,
  Input,
  Form,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import { NameSurname } from "./contactlistCol";


//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";
import DeleteModal from "components/Common/DeleteModal";
import axios from 'axios';

import "jspdf-autotable";

import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import Dropzone from 'react-dropzone-uploader';
import { Base64 } from 'js-base64';


import {
  getCons as onGetCons,
  addCons as onAddCons,
  updateCons as onUpdateCons,
  deleteCons as onDeleteCons,
} from "store/contacts/actions";
import { isEmpty } from "lodash";


//redux
import { useSelector, useDispatch } from "react-redux";

const ContactsList = props => {
  const tableRef = useRef(null);

  //meta title
  document.title = "Danışmanlık | CASTOR ADMIN PANELI";

  const dispatch = useDispatch();
  const [contact, setContact] = useState();
  const [contactEdit, setEditContact] = useState();

  const [getHtmlDataLong, setHtmlDataLong] = useState();
   const [getHtmlDataLongTwo, setHtmlDataLongTwo] = useState();
   const [getHtmlDataLongThird, setHtmlDataLongThird] = useState();


  const [getSubMediaID, setSubMediaID] = useState();


  // validation

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      title: (contactEdit && contactEdit.Title) || "",
      titleeng: (contactEdit && contactEdit.TitleEng) || "",
      titlethird: (contactEdit && contactEdit.TitleThird) || "",
  
      shortTitle: (contactEdit && contactEdit.Title_Short) || "",
      shortEngTitle: (contactEdit && contactEdit.TitleEng_Short) || "",
      shortThirdTitle: (contactEdit && contactEdit.TitleThird_Short) || "",

      sort:(contactEdit && contactEdit.Sort) || "",
    },
    validationSchema: Yup.object({
      title: Yup.string(3).required("Başlığı Giriniz"),


    }),
    onSubmit: values => {
      if (isEdit) {

        const updateUser = {
          ID: contactEdit.ID,
          Title: values.title,
          Sort: values.sort,
          TitleEng: values.titleeng,
          TitleThird: values.titlethird,
          ConsultancyDesc: Base64.encode(getHtmlDataLong),
          ConsultancyDescEng:Base64.encode(getHtmlDataLongTwo),
          ConsultancyDescThird:Base64.encode(getHtmlDataLongThird),
          ConsultancyImage: getFileName,
          ConsultancyCategoryID:getCategoryID,
          Title_Short : values.shortTitle,
TitleEng_Short: values.shortEngTitle,
TitleThird_Short: values.shortThirdTitle,
          

        };
        dispatch(onUpdateCons(updateUser));
        setIsEdit(false);
        validation.resetForm();
      } else {
        const newUser = {
          Title: values["title"],
          Sort: values["sort"],
          TitleEng:values["titleeng"],
          TitleThird:values["titlethird"],
          ConsultancyDesc: Base64.encode(getHtmlDataLong),
          ConsultancyDescEng:Base64.encode(getHtmlDataLongTwo),
          ConsultancyDescThird:Base64.encode(getHtmlDataLongThird),
          ConsultancyCategoryID:getCategoryID,
          ConsultancyImage: getFileName,
          Title_Short : values["shortTitle"],
          TitleEng_Short: values["shortEngTitle"],
          TitleThird_Short: values["shortThirdTitle"],

        };
        // save new user
        dispatch(onAddCons(newUser));
        validation.resetForm();
      }
      toggle();
    },
  });

  const { users } = useSelector(state => ({
    users: state.contacts.cons,
  }));


  const [modal, setModal] = useState(false);
  const [modalMedia, setModalMedia] = useState(false);

  const [isEdit, setIsEdit] = useState(false);
  const [getFileName, setFileName] = useState('');
  const [getCategoryID, setCategoryID] = useState(null);
  const [getCategory, setCategory] = useState([]);


  const columns = useMemo(
    () => [
      {
        Header: "#",
        Cell: () => {
          return <input type="checkbox" className="form-check-input" />;
        },
      },

      {
        Header: "Foto",
        // accessor: "name",
        disableFilters: true,
        filterable: true,
        accessor: (cellProps) => (
          <>
            {!cellProps.ConsultancyImage ? (
              <div className="avatar-xs">
                {cellProps.Title != null ? <span className="avatar-title rounded-circle">
                  {cellProps.Title.charAt(0)}
                </span> : null}
              </div>
            ) : (
              <div>
                <img
                  className="rounded-circle avatar-xs"
                  src={'https://megasubdomain.stechomeyazilim.info/' + cellProps.ConsultancyImage}
                  alt=""
                />
              </div>
            )}
          </>
        ),
      },

      {
        Header: "Başlık",
        accessor: "Title",
        filterable: true,
        Cell: cellProps => {
          return <NameSurname {...cellProps} />;
        },
      },

      {
        Header: "Kategori",
        accessor: "Consultancy_Category.Consultancy_Category_Title",
        filterable: true,
        Cell: cellProps => {
          return <NameSurname {...cellProps} />;
        },
      },
      {
        Header: "İşlemler",
        Cell: cellProps => {
          return (
            <div className="d-flex gap-3">

             
              <Link
                to="#"
                className="text-success"
                onClick={() => {
                  const userData = cellProps.row.original;
                  handleUserClick(userData);
                }}
              >
                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  Düzenle
                </UncontrolledTooltip>
              </Link>
              <Link
                to="#"
                className="text-danger"
                onClick={() => {
                  const userData = cellProps.row.original;
                  onClickDelete(userData);
                }}
              >
                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  Sil
                </UncontrolledTooltip>
              </Link>
            </div>
          );
        },
      },
    ],
    []
  );


  const _getAuthData = async () => {

    await axios.get(`https://getjob.stechomeyazilim.info:5101/getConsultancyCategoryCastor/select/`)
      .then((res) => {
        setCategory(res.data)
      })

   
  }


  useEffect(() => {
    _getAuthData()

    if (users && !users.length) {
      dispatch(onGetCons());
      setIsEdit(false);
    }
  }, [dispatch, users]);

  useEffect(() => {
    setContact(users);
    // setIsEdit(false);
  }, [users]);

  useEffect(() => {
    if (!isEmpty(users) && !!isEdit) {
      setContact(users);
      //setIsEdit(false);
    }
  }, [users]);

  const toggle = () => {
    setModal(!modal);
  };

  const toggleMedia = () => {
    setModalMedia(!modalMedia);
  };
  const [userList, setUserList] = useState([]);



  const handleUserClick = (arg) => {
    const user = arg;

    setEditContact(user);
    setCategoryID(user.ConsultancyCategoryID)
    setHtmlDataLong( Base64.decode(user.ConsultancyDesc))
    setHtmlDataLongTwo( Base64.decode(user.ConsultancyDescEng))
    setHtmlDataLongThird( Base64.decode(user.ConsultancyDescThird))

    setFileName(user.ConsultancyImage)

    setIsEdit(true);


    toggle();
  };

  var node = useRef();
  const onPaginationPageChange = page => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page);
    }
  };

  //delete customer
  const [deleteModal, setDeleteModal] = useState(false);

  const onClickDelete = users => {
    setContact(users);
    setDeleteModal(true);
  };

  const handleDeleteUser = () => {
    if (contact && contact.ID) {
      dispatch(onDeleteCons(contact.ID));
    }
    onPaginationPageChange(1);
    setDeleteModal(false);
  };

  const handleUserClicks = () => {
    setUserList("");
    setIsEdit(false);
    toggle();
  };
  const _setChoiceCategory = arg => {
    setCategoryID(arg.target.value)
  }


  const Preview = ({ meta }) => {
    const { name, percent, status, previewUrl } = meta;
    return (
      <div className="preview-box">
        <img src={previewUrl} /> <span className="name">{name}</span> - <span className="status">{status == "done" ? "Başarıyla Yüklendi" : "Yükleniyor"}</span>{status !== "done" && <span className="percent"> ({Math.round(percent)}%)</span>}
      </div>
    )
  }

  const _deleteMedia = async (itemData) => {

    try {
          
    axios.delete(`https://getjob.stechomeyazilim.info:5101/deleteProductImageLuxun/${itemData.ID}`).then(async(res) => {
      
    await axios.get(`https://getjob.stechomeyazilim.info:5101/getProductsMediaLuxun/select/${getSubMediaID}`)
    .then((res) => {
      setFileSubMedia(res.data)
    })

    
    })
    } catch (error) {
      //yield put(updateUserFail(error))
    }


  /*  const filteredData = getFileSubMediaAdd.filter(item => item.ID !== itemData.ID )
    setFileSubMediaAdd(filteredData);*/

  }



  const getUploadParams = ({ file }) => {
    const body = new FormData();
    body.append('dataFile', file);

    return { url: 'https://megaadmin.stechomeyazilim.info:8080/uploadfile', body }
  }
  


  const handleChangeStatus = ({ xhr }) => {
    if (xhr) {
      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          const result = JSON.parse(xhr.response);
          setFileName(result.file.filename)

        }
      }
    }
  }
  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteUser}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Yönetim" breadcrumbItem="Danışmanlık Listesi" />

          <Row>
            <Col lg="12">
              <Card>
                <CardBody>


                  <TableContainer
                    columns={columns}
                    data={users}
                    ref={tableRef}
                    isGlobalFilter={true}
                    isAddCons={true}
                    handleOrderClicks={handleUserClicks}
                    customPageSize={10}
                    className="custom-header-css"
                  />

                  <Modal isOpen={modalMedia} toggle={toggleMedia}>
                    <ModalHeader toggle={toggleMedia} tag="h4">
                      {!!isEdit ? "Düzenle" : "Medya Ekle"}
                    </ModalHeader>
                    <ModalBody>

                      <Row>
                        <Col xs={12}>


                          <div className="mb-3">
                            <label htmlFor="resume">Görsel</label>
                            <Dropzone
                              
                              getUploadParams={getUploadParams}
                              multiple={false}
                              onChangeStatus={handleChangeStatus}
                              styles={{
                                dropzone: { overflow: 'auto', border: '1px solid #999', background: '#f5f5f5' },
                                inputLabelWithFiles: { margin: '20px 3%' }
                              }}
                              canRemove={true}
                              PreviewComponent={Preview}
                              accept="image/*"
                            />
                          </div>

                        </Col>
                      </Row>
                      <Row>

                      
                            <div >
                              <img src={'https://megasubdomain.stechomeyazilim.info/' + getFileName} alt="" className="avatar-sm" />

                             
                              
                            </div>
                        



                       
                      </Row>

                    </ModalBody>
                  </Modal>

                  <Modal isOpen={modal} toggle={toggle}>
                    <ModalHeader toggle={toggle} tag="h4">
                      {!!isEdit ? "Düzenle" : "Danışmanlık Ekle"}
                    </ModalHeader>
                    <ModalBody>
                      <Form
                        onSubmit={e => {
                          e.preventDefault();
                          validation.handleSubmit();
                          return false;
                        }}
                      >
                        <Row>
                          <Col xs={12}>



                          <div className="mb-3">
                              <Label className="form-label">Danışmanlık Kategori Seçiniz</Label>
                              <Input
                                name="paymentStatus"
                                type="select"
                                className="form-select"
                                defaultValue={getCategoryID}
                                onChange={(text) => _setChoiceCategory(text)}
                                value={
                                  getCategoryID
                                }>
                                {getCategory.map((item, index) => (
                                  <option value={item.ID} key={item.ID}>{item.Consultancy_Category_Title}</option>
                                ))}

                              </Input>
                              {validation.touched.wsText && validation.errors.wsText ? (
                                <FormFeedback type="invalid">{validation.errors.wsText}</FormFeedback>
                              ) : null}
                            </div>
                            <div className="mb-3">
                              <Label className="form-label">Danışmanlık Başlık</Label>
                              <Input
                                name="title"
                                label="title"
                                type="text"
                                placeholder="Danışmanlık Başlık Giriniz"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.title || ""}
                                invalid={
                                  validation.touched.title &&
                                    validation.errors.title
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.title &&
                                validation.errors.title ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.title}
                                </FormFeedback>
                              ) : null}
                            </div>



                            <div className="mb-3">
                              <Label className="form-label">Uzun Açıklama</Label>

                              <Form method="post">
                                <CKEditor
                                  editor={ClassicEditor}
                                  data={getHtmlDataLong}
                                  onReady={editor => {
                                    // You can store the "editor" and use when it is needed.
                                    console.log('Editor is ready to use!', editor);
                                  }}
                                  onChange={(event, editor) => {
                                    const data = editor.getData();
                                    setHtmlDataLong(data)

                                  }}
                                />
                              </Form>
                            </div>

                          



                            <div className="mb-3">
                              <Label className="form-label">Danışmanlık Başlık Eng</Label>
                              <Input
                                name="titleeng"
                                label="titleeng"
                                type="text"
                                placeholder="Eng Danışmanlık Başlık Giriniz"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.titleeng || ""}
                                invalid={
                                  validation.touched.titleeng &&
                                    validation.errors.titleeng
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.titleeng &&
                                validation.errors.titleeng ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.titleeng}
                                </FormFeedback>
                              ) : null}
                            </div>



                            <div className="mb-3">
                              <Label className="form-label">Uzun Açıklama Eng</Label>

                              <Form method="post">
                                <CKEditor
                                  editor={ClassicEditor}
                                  data={getHtmlDataLongTwo}
                                  onReady={editor => {
                                    // You can store the "editor" and use when it is needed.
                                    console.log('Editor is ready to use!', editor);
                                  }}
                                  onChange={(event, editor) => {
                                    const data = editor.getData();
                                    setHtmlDataLongTwo(data)

                                  }}
                                />
                              </Form>
                            </div>




                            <div className="mb-3">
                              <Label className="form-label">Danışmanlık Başlık Third</Label>
                              <Input
                                name="titlethird"
                                label="titlethird"
                                type="text"
                                placeholder="Third Danışmanlık Başlık Giriniz"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.titlethird || ""}
                                invalid={
                                  validation.touched.titlethird &&
                                    validation.errors.titlethird
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.titlethird &&
                                validation.errors.titlethird ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.titlethird}
                                </FormFeedback>
                              ) : null}
                            </div>


                            <div className="mb-3">
                              <Label className="form-label">Kısa Başlık TR</Label>
                              <Input
                                name="shortTitle"
                                label="shortTitle"
                                type="text"
                                placeholder="Kısa Başlık Giriniz"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.shortTitle || ""}
                                invalid={
                                  validation.touched.shortTitle &&
                                    validation.errors.shortTitle
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.shortTitle &&
                                validation.errors.shortTitle ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.shortTitle}
                                </FormFeedback>
                              ) : null}
                            </div>

                            <div className="mb-3">
                              <Label className="form-label">Kısa ENG</Label>
                              <Input
                                name="shortEngTitle"
                                label="shortEngTitle"
                                type="text"
                                placeholder="Kısa Eng Başlık Giriniz"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.shortEngTitle || ""}
                                invalid={
                                  validation.touched.shortEngTitle &&
                                    validation.errors.shortEngTitle
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.shortEngTitle &&
                                validation.errors.shortEngTitle ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.shortEngTitle}
                                </FormFeedback>
                              ) : null}
                            </div>

                            <div className="mb-3">
                              <Label className="form-label">Kısa 3.Dil</Label>
                              <Input
                                name="shortThirdTitle"
                                label="shortThirdTitle"
                                type="text"
                                placeholder="Kısa 3.Dil Başlık Giriniz"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.shortThirdTitle || ""}
                                invalid={
                                  validation.touched.shortThirdTitle &&
                                    validation.errors.shortThirdTitle
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.shortThirdTitle &&
                                validation.errors.shortThirdTitle ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.shortThirdTitle}
                                </FormFeedback>
                              ) : null}
                            </div>
                            <div className="mb-3">
                              <Label className="form-label">Sıralama</Label>
                              <Input
                                name="sort"
                                label="sort"
                                type="sort"
                                placeholder="Sıralama Giriniz"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.sort || ""}
                                invalid={
                                  validation.touched.sort &&
                                    validation.errors.sort
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.sort &&
                                validation.errors.sort ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.sort}
                                </FormFeedback>
                              ) : null}
                            </div>




                            <div className="mb-3">
                              <Label className="form-label">Uzun Açıklama Third</Label>

                              <Form method="post">
                                <CKEditor
                                  editor={ClassicEditor}
                                  data={getHtmlDataLongThird}
                                  onReady={editor => {
                                    // You can store the "editor" and use when it is needed.
                                    console.log('Editor is ready to use!', editor);
                                  }}
                                  onChange={(event, editor) => {
                                    const data = editor.getData();
                                    setHtmlDataLongThird(data)

                                  }}
                                />
                              </Form>
                            </div>

                            <div className="mb-3">
                              <Col xs={12} className="mb-3">
                                <label htmlFor="resume">Görsel</label>
                                <Dropzone
                                  getUploadParams={getUploadParams}
                                  onChangeStatus={handleChangeStatus}
                                  styles={{
                                    dropzone: { overflow: 'auto', border: '1px solid #999', background: '#f5f5f5' },
                                    inputLabelWithFiles: { margin: '20px 3%' }
                                  }}

                                  canRemove={false}
                                  PreviewComponent={Preview}
                                  accept="image/*"
                                />

                              </Col>
                            </div>


                          
                          </Col>
                        </Row>
                        <Row>



                          <Col>
                            <div className="text-end">
                              <button
                                type="submit"
                                className="btn btn-success save-user"
                              >
                                Kaydet
                              </button>
                            </div>
                          </Col>
                        </Row>
                      </Form>
                    </ModalBody>
                  </Modal>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(ContactsList);
